import Vue from 'vue';
import Vuex from 'vuex';
import NotificationService from "./services/notificationService";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        orgId: null,
        spaceId: null,
        selectedUserId: null,
        notifications: [],
    },
    mutations:{
        setOrgId: (state, orgId) => { state.orgId = orgId },
        setSpaceId: (state, spaceId) => { state.spaceId = spaceId },
        setSelectedUserId: (state, selectedUserId) => { state.selectedUserId = selectedUserId },
        addNotification: (state, notification) => { state.notifications.push(notification) },
        removeNotification(state, notification) {
            const index = state.notifications.findIndex(n => n.id === notification.id);
            if (index !== -1) {
              state.notifications.splice(index, 1);
            }
        },
        removeAllNotifications(state) {
            state.notifications = [];
        },
    },
    actions: {
        removeNotification({ commit }, notification) {
            NotificationService.remove(notification);
            commit('removeNotification', notification);
        },
        removeAllNotifications({ commit }) {
            commit('removeAllNotifications');
        }
    },
    getters: {
        notifications: state => state.notifications,
    },
});