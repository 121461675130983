const passwordValidator = (value, options) => {
    const { RequiredLength, RequireLowercase, RequireUppercase, RequireNonAlphanumeric, RequireDigit } = options.Password;
    const validate = () => {
        const lowercaseRegex = RequireLowercase ? /(?=.*[a-z])/ : /^$/;
        const uppercaseRegex = RequireUppercase ? /(?=.*[A-Z])/ : /^$/;
        const nonAlphanumericRegex = RequireNonAlphanumeric ? /(?=.*[\W])/ : /^$/;
        const digitRegex = RequireDigit ? /(?=.*\d)/ : /^$/;
        const regex = new RegExp(`^${lowercaseRegex.source}${uppercaseRegex.source}${nonAlphanumericRegex.source}${digitRegex.source}.{${RequiredLength},}$`);
        return regex.test(value);
    };
    const errorMessage = `Password must be at least ${RequiredLength} characters long and contain at least one lowercase letter, one uppercase letter, one non-alphanumeric character, and one digit.`;
    return { validate, errorMessage };
};

export default passwordValidator;