<template>
    <div class="content-wrapper">
        <div class="row">
            <div id="breadcrumb" class="breadC">
                <!-- <a href="/home" style="text-decoration: none;color: #F77C09!important;"
                    class="sub-head-inactive-title">INDUNA
                </a> -->
                <!-- <span class="sub-head-active-title"> {{ model }}|</span> -->
                <!-- <span class="sub-head-active-title">{{ $route.name.toUpperCase() }}</span> -->
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-md-4 col-sm-12" style="background-color: white; padding: 45px; ">
                <div class="row" style="justify-content: center;">
                    <h3 class="">Change Password</h3>
                </div>
                <div>
                    <ul>
                        <li>Required Minimum Length: 10 characters</li>
                        <li>Required Lowercase: 1 character</li>
                        <li>Required Uppercase: 1 character</li>
                        <li>Required Non-Alphanumeric: 1 character</li>
                        <li>Required Digit: 1 character</li>
                    </ul>
                </div>
                <form @submit.prevent="changePassword">
                    <b-row align-h="center" class="mb-0 px-0">
                        <b-col cols="12" v-for="notification in notifications" :key="notification.id">
                            <b-alert show :variant="notification.type" dismissible @dismissed="removeNotification(notification)">
                                {{ notification.message }}
                            </b-alert>
                        </b-col>
                        <b-col cols="10" class="px-0">
                            <label for="newPassword">NEW PASSWORD</label>
                            <div id="newPassword">
                                <input input class="input-show-hide-password" v-model="newPassword" @blur="validateNewPassword"
                                :type="showNewPassword ? 'text' : 'password'" autocomplete="off">
                                <i v-if="!showNewPassword" @click="toggleNewPasswordVisibility()"
                                    class="bi bi-eye-slash-fill"></i>
                                <i v-if="showNewPassword" @click="toggleNewPasswordVisibility()" class="bi bi-eye-fill"></i>
                            </div>
                        </b-col>
                        <b-col cols="10" class="px-0 mt-2 pt-2">
                            <label for="confirmPassword">CONFIRM PASSWORD</label>
                            <div id="confirmPassword">
                                <input class="input-show-hide-password" v-model="confirmPassword" @blur="validateConfirmPassword"
                                    :type="showConfirmPassword ? 'text' : 'password'" autocomplete="off">
                                <i v-if="!showConfirmPassword" @click="toggleConfirmPasswordVisibility()"
                                    class="bi bi-eye-slash-fill"></i>
                                <i v-if="showConfirmPassword" @click="toggleConfirmPasswordVisibility()"
                                    class="bi bi-eye-fill"></i>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" class="mx-0 mb-0 mt-2">
                        <b-button class="btn btnprimary btn-create" @click="changePassword()">
                            <b-spinner small v-if="isLoading"></b-spinner>
                            Change Password
                        </b-button>
                    </b-row>
                    <b-row align-h="center" class="mx-0 mb-0 mt-2">
                        <b-button class="btn btnprimary" @click="navigateBack()">
                            Cancel
                        </b-button>
                    </b-row>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import api from '@/api';
import { mapState, mapGetters, mapActions } from 'vuex';
import passwordValidator from '@/validators/passwordValidator';

export default {
    data: () => ({
        newPassword: '',
        confirmPassword: '',
        passwordFieldType: "password",
        showNewPassword: false,
        showConfirmPassword: false,
        options:{
            Password: {
                RequiredLength: 10,
                RequireLowercase: true,
                RequireUppercase: true,
                RequireNonAlphanumeric: true,
                RequireDigit: true
            }
        },
        isLoading: false
    }),
    computed: {
        ...mapState(['selectedUserId']),
        ...mapGetters(['notifications'])
    },
    created() {
        if (this.$route.params["userId"]) {
            this.$store.commit('setSelectedUserId', this.$route.params["userId"])
        } else if (!this.selectedUserId){
            this.navigateBack();
        }
    },
    methods: {
        ...mapActions(['removeNotification']),
        validateNewPassword() {
            const validator = passwordValidator(this.newPassword, this.options);
            this.validateField("newPassword", validator);
        },
        validateConfirmPassword() {
            const validator = passwordValidator(this.confirmPassword, this.options);
            this.validateField("confirmPassword", validator);
        },
        toggleNewPasswordVisibility() {
            this.showNewPassword = !this.showNewPassword;
        },
        toggleConfirmPasswordVisibility() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        navigateBack() {
            if (!this.$route.params["userId"] && this.selectedUserId) {
                this.$router.push({
                    path: `/ManageUser/ViewUser`
                })
            }
            else {
                this.$router.push({
                    path: '/'
                })
            }
        },
        changePassword() {
            if (this.newPassword !== this.confirmPassword) {
                this.$notification.error("Passwords do not match.");
                return;
            }

            if (this.newPassword === null || this.confirmPassword.trim() == "") {
                this.validateNewPassword();
            }

            if (this.confirmPassword === null || this.confirmPassword.trim() == "") {
                this.validateConfirmPassword();
            }

            if (this.isValid()) {
                this.isLoading = true

                let e = () => {
                    this.isLoading = false
                    this.$notification.error("Failed to change password.");
                }

                let s = () => {
                    this.isLoading = false
                    this.$notification.success("Password changed successfully.");
                    this.navigateBack();
                }

                let request = {
                    'userId': `${this.selectedUserId}`,
                    'password': this.newPassword,
                    'token': this.$route.query.token
                }
                api.completePasswordReset(request, s, e)
            } else {
                this.$notification.error("Please complete the form.", 3000);
            }
        }
    }
}
</script>

<style>
.reset-page-btns {
    margin-left: 8%;
}
.btn-create{
        background: #F77C09 !important;
        border-color: #F77C09;
        color: #fff;        
}
</style>