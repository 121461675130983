/* eslint-disable */

import axios from 'axios'

let authGetInstance = axios.create({
    method: "get",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`
        }
});

let authPostInstance = axios.create({
    method: "post",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`,
            'Content-Type': 'application/json'
        }
});

var layerService = {

   baseURL: "",

    getAssetItems(details, s, e) {

        authPostInstance({

            url: this.baseURL + "/api/Assets/FindAssets",
            data:details, 
        })
        .then(function (resp) {
          
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to search for asset items");
        });
    }   


}
export default layerService;