export const formValidatorService = {
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    validateField(id, validator) {
      const errorSpanId = `${id}-error`;
      let errorSpan = document.getElementById(errorSpanId);

      if (!errorSpan) {
        errorSpan = document.createElement("span");
        errorSpan.id = errorSpanId;
        errorSpan.innerText = validator.errorMessage;
        errorSpan.classList.add("error-message");

        const input = document.getElementById(id);
        input.insertAdjacentElement("afterend", errorSpan);
      }

      if (!validator.validate()) {
        const input = document.getElementById(id);
        input.classList.add("error");
        errorSpan.classList.add("active");

        if (!this.errors.includes(id)) {
          this.errors.push(id);
        }
      } else {
        const input = document.getElementById(id);
        input.classList.remove("error");
        errorSpan.classList.remove("active");

        const index = this.errors.indexOf(id);
        if (index !== -1) {
          this.errors.splice(index, 1);
        }

        if (errorSpan.classList.contains("active")) {
          errorSpan.classList.remove("active");
        }

        errorSpan.remove();
      }
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
};