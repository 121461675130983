import axios from "axios";
import Vue from 'vue';
const { config } = Vue;

const getUserRole = () => {
    const userData = localStorage.getItem("user_data");
    if (userData) {
        const userObj = JSON.parse(userData);
        return userObj.role;
    }
    return null;
};

let authGetInstance = axios.create({
    method: "get",
});

let authPostInstance = axios.create({
    method: "post",
    headers:
    {
        'Content-Type': 'application/json'
    }
});
let authPutInstance = axios.create({
    method: "put",
    headers:
    {
        'Content-Type': 'application/json'
    }
});

let authDeleteInstance = axios.create({
    method: "delete",
    headers:
    {
        'Content-Type': 'application/json'
    }
});

let nonAuthPostInstance = axios.create({
    method: "post",
    headers:
    {
        'Content-Type': 'application/json'
    }
});

authDeleteInstance.interceptors.request.use(function (config) {
    const apiKey = localStorage.getItem("api_Key");

    const { headers } = config;
    headers.Authorization = `Bearer ${apiKey}`;

    return config;
}, function (error) {
    return Promise.reject(error);
});
authGetInstance.interceptors.request.use(function (config) {
    const apiKey = localStorage.getItem("api_Key");

    const { headers } = config;
    headers.Authorization = `Bearer ${apiKey}`;

    return config;
}, function (error) {
    return Promise.reject(error);
});
authPostInstance.interceptors.request.use(function (config) {
    const apiKey = localStorage.getItem("api_Key");

    const { headers } = config;
    headers.Authorization = `Bearer ${apiKey}`;

    return config;
}, function (error) {
    return Promise.reject(error);
});

authPutInstance.interceptors.request.use(function (config) {
    const apiKey = localStorage.getItem("api_Key");

    const { headers } = config;
    headers.Authorization = `Bearer ${apiKey}`;
    
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default {
    Url: config.ApiUrl,
    //AssetpropertyValues

    getAssetPropertyValues(assetId, s, e) {
        authGetInstance({
            url: this.Url + "/api/AssetPropertyValues/GetByAsset/" + assetId,
        })
            .then(function (resp) {
                console.log(resp)
                console.log("callback function:", s);
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Asset Property Values");
            });
    },
    getAllAssetTypeProperties(assetTypeId, s, e) {
        authGetInstance({
            url: this.Url + "/api/AssetTypeProperties/GetByAssetType/" + assetTypeId,
        })
            .then(function (resp) {
                console.log(resp)
                console.log("callback function:", s);
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Asset Type Properties");
            });
    },
    getAllProperties(s, e) {
        authGetInstance({
            url: this.Url + "/api/Properties",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Properties");
            });
    },

    //AssetTypes
    async GetAllAssetTypes(handleSuccess, handleError) {
        await authGetInstance({
            url: this.Url + "/api/AssetTypes",
        })
        .then((response) => {
            const { data } = response;
            handleSuccess(data);
        })
        .catch((error) => {
            const errorDescription = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async GetAssetTypeById(id, handleSuccess, handleError) {
        await authGetInstance({
            url : this.Url + `/api/AssetTypes/${id}`
        })
        .then((response) => {
            const { data } = response;
            handleSuccess(data);
        })
        .catch((error) => {
            const errorDescription = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async GetAssetTypeBySpaceType(id, handleSuccess, handleError) {
        await authGetInstance({
            url : this.Url + `/api/AssetTypes/GetBySpaceType/${id}`
        })
        .then((response) => {
            const { data } = response;
            handleSuccess(data);
        })
        .catch((error) => {
            const errorDescription = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async CreateAssetType(details, handleSuccess, handleError) {
        await authPostInstance({
            url: this.Url + "/api/AssetTypes",
            data: details,
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },   
    async UpdateAssetType(data, handleSuccess, handleError) {
        await authPutInstance({
            data: data,
            url : this.Url + `/api/AssetTypes/${data.id}`,
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async DeleteAssertType(id, handleSuccess, handleError) {
        await authDeleteInstance({
            url: this.Url + "/api/AssetTypes/" + id,
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    //AssetCreatedDates
    findAssetCreatedDatesForSpaceAndAssetTypes(spaceId, assetTypeIds, s, e) {
        const data = {
            spaceId: spaceId,
            assetTypeIds: assetTypeIds
        };

        authPostInstance({
            url: this.Url + "/api/Assets/FindAssetCreatedDates",
            headers: { "Content-Type": "application/json" },
            data: data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load asset created dates");
            });
    },

    //Assets
    getAllExistingAssets(s, e) {
        authGetInstance({
            url: this.Url + "/api/Assets/AllAssets",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Assets");
            });
    },
    async getAllAssets(spaceId, assetTypeIds, s, e, exclude = null) {
        const data = {
            spaceId: spaceId,
            assetTypeIds: assetTypeIds,
            ExcludeRelatedEntities: exclude,
        };

        authPostInstance({
            url: this.Url + "/api/Assets/FindAssets",
            headers: { "Content-Type": "application/json" },
            data: data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load assets");
            });
    },
    DeleteLayer(id, s, e) {
        authPostInstance({
            method: 'post',
            url: `${this.Url}/api/Assets/Delete/${id}`,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete assets");
            });
    },
    CreateAsset(details, s, e) {
        authPostInstance({
            url: this.Url + "/api/Assets",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to create assets");
            });
    },
    EditLayerDrawing(id, details, s, e) {
        authPostInstance({
            url: `${this.Url}/api/Assets/EditAssetDrawing/${id}`,
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update assets");
            });
    },

    getAssetID(assetId, s, e) {
        authGetInstance({
            url: this.Url + "/api/Assets/" + assetId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get assets by id");
            });
    },

    UpdateAsset(details, s, e) {
        authPostInstance({
            url: this.Url + "/api/Assets/UpdateAssetDetails",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update assets");
            });
    },
    //Work Item
    getAllItems(s, e) {
        authGetInstance({
            url: this.Url + "/WorkItem/readAll",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load work items");
            });
    },
    DeleteWorkItem(id, s, e) {
        authPostInstance({
            url: this.Url + "/WorkItem/delete?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete work items");
            });
    },

    CreateWorkItem(details, s, e) {
        authPostInstance({
            url: this.Url + "/WorkItem/create",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to create work items");
            });
    },

    getWorkItemID(workitemId, s, e) {
        authGetInstance({
            url: this.Url + "/WorkItem/read?id=" + workitemId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get work items by id");
            });
    },

    UpdateWorkItem(details, s, e) {
        authPostInstance({
            url: this.Url + "/WorkItem/update",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
                console.log(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Work Items");
            });
    },


    //Work Order

    getAllOrders(s, e) {
        authGetInstance({
            url: this.Url + "/WorkOrder/readAll",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Work Order");
            });
    },
    CreateWorkOrder(details, s, e) {
        console.log(details)
        authPostInstance({
            url: this.Url + "/WorkOrder/create",
            data: details,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Create Work Order");
            });
    },

    DeleteWorkOrder(id, s, e) {
        authPostInstance({
            url: this.Url + "/WorkOrder/delete?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Work Order");
            });
    },

    UpdateWorkOrder(details, s, e) {
        authPostInstance({
            url: this.Url + "/WorkOrder/update",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Work Order");
            });
    },

    getWorkOrderID(workorderId, s, e) {
        authGetInstance({
            url: this.Url + "/WorkOrder/read?id=" + workorderId
        })
            .then(function (resp) {
                console.log("response", resp)
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Work Order by id");
            });
    },

    //Spaces
    async getAllSpaces(s, e) {
        await authGetInstance({
            url: this.Url + "/Space/readAll",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Space");
            });
    },
   
    getAllSpacesForOrg(id, s, e) {
        authGetInstance({
            url: this.Url + "/Space/getAllSpacesForOrg?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Space");
            });
    },

    async DeleteSpace(id, s, e) {
         await authPostInstance({
            url: this.Url + "/Space/delete?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
                e(errorDescription);
            });
    },
    CreateSpace(details, s, e) {
        authPostInstance({
            url: this.Url + "/Space/create",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Create Space");
            });
    },

    getSpaceID(id, s, e) {
        authGetInstance({
            url: this.Url + "/Space/read?id=" + id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Space by id");
            });
    },
    UpdateSpaces(details, s, e) {
        authPostInstance({
            url: this.Url + "/Space/update",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Space");
            });
    },


    //Space Types

    async GetAllSpaceTypes(handleSuccess, handleError) {
        await authGetInstance({
            url : this.Url + '/api/SpaceTypes',
        })
        .then((response) => {
            const { data } = response;
            handleSuccess(data);
        })
        .catch((error) => {
            const errorDescription = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async GetSpaceType(data, handleSuccess, handleError) {
        await authGetInstance({
            url : this.Url + `/api/SpaceTypes/${data}`
        })
        .then((response) => {
            const { data } = response;
            handleSuccess(data);
        })
        .catch((error) => {
            const errorDescription = (error.response && error.response.data)
                ? error.response.data.message
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },

    async CreateSpaceType(data, handleSuccess, handleError) {
        await authPostInstance({
            data: data,
            url : this.Url + '/api/SpaceTypes',
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },
    async DeleteSpaceType(data, handleSuccess, handleError) {
        await authDeleteInstance({
            url : `${this.Url}/api/SpaceTypes/Delete/${data}`,
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
        });
    },
    async UpdateSpaceType(data, handleSuccess, handleError) {
        await authPutInstance({
            data: data,
            url : this.Url + `/api/SpaceTypes/Update/${data.id}`,
        })
        .then((response) => {
            if([200,201].includes(response.status)) {
                handleSuccess();
            }
        })
        .catch((error) => {
            const errorDescription = (error?.response && error?.response?.data)
                ? error.response.data
                : error.message;
            handleError(errorDescription);
            throw new Error(errorDescription);
        });
    },

    //Services
    getAllServices(s, e) {
        authGetInstance({
            url: this.Url + "/Service/readAll",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load orgnisations");
            });
    },
    getAllServiceRequestsForSpace(id, s, e) {
        authGetInstance({
            url: this.Url + "/Service/getAllServiceRequestsForSpace?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load services for space");
            });
    },
    DeleteService(id, s, e) {
        authPostInstance({
            url: this.Url + "/Service/delete?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Services");
            });
    },
    CreateService(details, s, e) {
        authPostInstance({
            url: this.Url + "/Service/create",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Create Service");
            });
    },

    getServiceID(serviceId, s, e) {
        authGetInstance({
            url: this.Url + "/Service/read?id=" + serviceId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Service by id");
            });
    },

    UpdateService(details, s, e) {
        authPostInstance({
            url: this.Url + "/Service/update",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
                console.log(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Service");
            });
    },
    //orgs
    getAllOrgs(s, e) {
        authGetInstance({
            url: this.Url + "/Orgs/GetAll",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load orginisations");
            });
    },
    GetAllOrgsPerUser(userId, s, e) {
        authGetInstance({
            url: this.Url + "/Orgs/GetAllOrgsPerUser/" + userId,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load orginisations for userId");
            });
    },
    DeleteOrgs(id, s, e) {
        authDeleteInstance({
            url: this.Url + "/Orgs/Delete/" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete organisation");
            });
    },
    CreateOrgs(details, s, e) {
        authPostInstance({
            url: this.Url + "/Orgs/Create",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to create organisation");
            });
    },

    getOrgID(orgId, s, e) {
        authGetInstance({
            url: this.Url + "/Orgs/Get/" + orgId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get organisation by id");
            });
    },

    updateOrgs(details, s, e) {
        authPostInstance({
            url: this.Url + "/Orgs/Update",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update organisations");
            });
    },

    // Reports
    generateReportDataAndExportToExcel(report, s, e) {
        authPostInstance({
            url: this.Url + "/api/Reports/generate",
            data: report
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to generate report data.");
            });
    },
    generatePropertyReportDataAndExportToExcel(layerId, s, e) {
        authGetInstance({
            url: this.Url + "/api/Reports/generate/" + layerId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to generate report data.");
            });
    },
    // Users 
    getAllUsers(s, e) {
        const userRole = getUserRole();
        //console.log(userRole);

        authGetInstance({
            url: this.Url + "/User/GetAllUsers",
        })
        .then(function (resp) {
            
            //console.log(JSON.stringify(resp.data));
            if (userRole === "Administrator")
            {
                s(resp.data);
            }
            else {
                s(resp.data.filter(user => !user.roles.includes("Administrator")));
            }
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to load users");
        });
    },
    getRoleById(id, s, e) {
        authGetInstance({
            url: this.Url + "/User/GetRole?id=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get role");
            });
    },
    SearchByEmail(email, s, e) {
        authPostInstance({
            url: this.Url + "/User/findByEmail/" + email + "?key=" + localStorage.getItem("api_Key"),

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to find users by email");
            });

    },
    getUserByEmail(email, s, e) {
        const encodedEmail = encodeURIComponent(email);
        authGetInstance({
            url: this.Url + "/User/GetByEmail?email=" + encodedEmail
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get user by email");
            });
    },
    getUserID(userId, s, e) {
        authGetInstance({
            url: this.Url + "/User/GetUser?id=" + userId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get user by id");
            });
    },
    async CreateUser(details, s, e) {
        const { protocol, hostname, port } = window.location;
        let baseAppBaseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;

        // Include the BaseURL field in the request payload
        details.BaseURL = `${baseAppBaseUrl}/setPassword/`;

        await authPostInstance({
            url: this.Url + "/User/Create",
            data: details,
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
                // If it's a client-side error (4xx)
                const responseData = error.response.data;
                if (responseData) {
                if (Array.isArray(responseData) && responseData.length > 0) {
                    // If the response is an array, take the first error message
                    e(responseData[0]);
                } else if (typeof responseData === 'object') {
                    // If the response is an object, extract the first property value
                    const firstProperty = Object.values(responseData)[0];
                    if (firstProperty) {
                        e(firstProperty);
                    } else {
                    // If there's no property, return a generic error message
                    e("Failed to create user");
                    }
                } else {
                    // For other cases, return a generic error message
                    e("Failed to create user");
                }
                } else {
                // If there's no specific error message, return a generic error message
                e("Failed to create user");
                }
            } else {
                // For other types of errors, log the details and return a generic error message
                console.error(error);
                e("Failed to create user");
            }
        });
    },
    UpdateUser(details, s, e) {
        const apiKey = localStorage.getItem("api_Key");

        console.log(`Bearer ${apiKey}`);
        authPostInstance({
            url: this.Url + "/User/Update",
            data: details,
            headers: {
                Authorization: apiKey ? `Bearer ${apiKey}` : "",
                'Content-Type': 'application/json'
            }
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update user");
            });
    },
    UpdateRole(details, roleId, s, e) {
        authPostInstance({
            url: this.Url + "/User/UpdateRole?roleId=" + roleId,
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update user");
            });
    },

    UpdateOrginisation(details, s, e) {
        authPostInstance({
            url: this.Url + "/User/LinkUserOrg",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update users");
                console.log(e, "Failed to update users")
            });
    },

    UnlinkOrginisation(details, s, e) {
        authPostInstance({
            url: this.Url + "/User/UnlinkUserOrg",
            data: details,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update users");
                console.log(e, "Failed to update users")
            });
    },

    DeleteUser(id, s, e) {
        authPostInstance({
            url: this.Url + "/User/Delete?userId=" + id,
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete user");
            });
    },

    // Auth Login
    getAuthLogin(details, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/login",
            data: details
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to login user");
            });
    },

    getAuthRegister(s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/Register"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Register Users");
            });
    },

    getRoles(s, e) {
        authGetInstance({
            url: this.Url + "/User/GetAllRoles"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Roles");
            });
    },
    sendRequestToResetPassword(email, s, e) {
        const { protocol, hostname, port } = window.location;
        let baseAppBaseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
        nonAuthPostInstance({
            url: this.Url + "/Auth/SendPasswordResetEmail",
            data: { 'email': email, 'baseURL': `${baseAppBaseUrl}/setPassword/` }
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to login user");
            });
    },
    completePasswordReset(payload, successCallback, errorCallback) {
        const axiosInstance = payload.token ? nonAuthPostInstance : authPostInstance;
        axiosInstance({
            url: `${this.Url}/Auth/CompletePasswordReset`,
            data: payload
        })
            .then((response) => {
                successCallback(response.data);
            })
            .catch((error) => {
                console.error(error);
                errorCallback("Failed to login user");
            });
    },
    async uploadFileAsync(payload) {
        try {
            const apiKey = localStorage.getItem("api_Key");
            const response = await axios.post(`${this.Url}/S3/upload`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${apiKey}`,
                }
            });
            return response;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to upload file");
        }
    },
    getS3Config(s, e) {
        authGetInstance({
          url: this.Url + "/S3/config",
        })
          .then(function (resp) {
            const { bucketName, prefix } = resp.data; // Use lowercase property names
            const s3Config = {
              BucketName: bucketName, // Assign to uppercase property names
              Prefix: prefix, // Assign to uppercase property names
            };
            s(s3Config);
          })
          .catch(function (error) {
            console.error(error);
            e("Failed to load S3 Config");
          });
      }
}