<template>
    <div>
        <b-row align-h="center" align-v="center" class="full-height login-container mb-0">
            <b-col cols="4">
                <b-card-header class="loginleft">
                    <img src="@/assets/Induna_logo_final_vertical.png" alt="" class="img-to-fit3">
                </b-card-header>
            </b-col>
            <b-col cols="4">
                <b-card-text class="loginright">
                    <h2 style="text-align: center;">Welcome !</h2>
                    <h3 style="text-align: center;">Please sign in to continue.</h3>
                    <br />
                    <b-col cols="12" v-for="notification in notifications" :key="notification.id">
                        <b-alert show :variant="notification.type" dismissible @dismissed="removeNotification(notification)">
                            {{ notification.message }}
                        </b-alert>
                    </b-col>
                    <br />
                    <b-row align-h="center" class="mb-0 px-0">
                        <b-col cols="10" class="px-0">
                            <label>USERNAME</label>
                            <input type="email" v-model="email" v-bind:class="{ 'error': errors.email }">
                            <div v-if="errors.email" class="error-message">
                                {{ errors.email }}
                            </div>
                        </b-col>
                        <b-col cols="10" class="px-0 mt-2 pt-2">
                            <label>PASSWORD</label>
                            <input class="input-show-hide-password" :type="passwordFieldType" v-model="password"
                                v-bind:class="{ 'error': errors.password }">
                            <div v-if="errors.password" class="error-message">
                                {{ errors.password }}
                            </div>
                            <button class="input-show-hide-password-button" type="password" @click="switchVisibility">
                                <i v-if="!passwordVisible" class="bi bi-eye-slash-fill"></i>
                                <i v-else class="bi bi-eye-fill"></i>
                            </button>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col cols="6">
                            <!--Commented out for the time being, can test this functionality at a later stage of project-->
                            <!-- <b-form-checkbox class="mt-3 ml-5 checktext" id="checkbox-1" v-model="status"
                                name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                <label> Remember Me</label>
                            </b-form-checkbox> -->
                        </b-col>
                        <b-col cols="6">
                            <b-button class="mt-2 ml-4 primary-no-border" @click="forgotPassword()"
                                variant="outline-primary" block squared>
                                <label> Forgot Password?</label>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" class="mx-0 mb-0">
                        <b-button class="mt-3 btnprimary3" @click.prevent="loginUser()" size="sm"  block  v-on:keyup.enter="loginUser()"
                            squared style="border-radius: 7px!important;">
                            <b-spinner small v-if="isLoading"></b-spinner>
                            Log In
                        </b-button>
                        <p v-if="error" class="error-message">Invalid username or password</p>
                    </b-row>
                </b-card-text>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import api from '@/api'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {},
    data: () => ({
        userName:'',
        email: '',
        password: '',
        passwordFieldType: "password",
        passwordVisible: false,
        errors: {
            email: '',
            password: ''
        },
        isLoading: false,
        status:'',
        userData: "",
        error: false
    }),
    computed: {
        ...mapGetters(['notifications'])
    },
    methods: {
        ...mapActions(['removeNotification']),
        validateEmail() {
            if (!this.email.includes('@')) {
                this.errors.email = 'Email must be a valid email address';
            } else {
                this.errors.email = '';
            }
        },
        //Uncomment the below if you wish to include validation on password length - BUT ensure password length enforcement is in place on password creation before doing so.
        // validatePassword() {
        //     if (this.password.length < 12) {
        //         this.errors.password = 'Password must be at least 12 characters long';
        //     } else {
        //         this.errors.password = '';
        //     }
        // },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.passwordVisible = !this.passwordVisible;
        },
        forgotPassword() {
            this.$router.push({
                path: '/forgot'
            })
        },
        loginUser() {
            this.isLoading = true;
            let details = {
                email: this.email,
                password: this.password
            }
            let e = (msg) => {
                console.error(msg)
                this.isLoading = false
                this.error = true
            }
            let s = (data) => {
                localStorage.setItem("api_Key", data.token);
                var base64Url = data.token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                var currentData = JSON.parse(jsonPayload);
                // currentData.role = currentData.role;
                localStorage.setItem("user_data", JSON.stringify(currentData));
                console.log(data)
                this.userData = currentData
                // window.localStorage.setItem("email", this.userData.email)
                // window.localStorage.setItem("firstName", this.userData.firstName)
                // window.localStorage.setItem("surname", this.userData.surname)
                // window.localStorage.setItem("apiKey", this.userData.apiKey)

                if (this.password === this.email) {
                    window.localStorage.setItem("hasDefaultPassword", true)
                }
                this.isLoading = false
                this.$router.push({
                    path: '/home'
                })
            }
            console.log(details)

            api.getAuthLogin(details, s, e)

        },
    },
    watch: {
    email: function() {
      this.validateEmail();
    },
    // password: function() {
    //   this.validatePassword();
    // }
  }
}
</script>
<style>
h3 {
    color: #000000;
}

h2 {
    color: #000000;
}
.error {
  border: 1px red;
}
.error-message {
  color: red;
  width:90%;
}
</style>