import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import {
  Roles,
} from '@/constants';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      
    }
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/setPassword/:userId',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/OuterView.vue'),
    children: [
      {
        path: '/home',
         name: 'Home',
         component: () => import('../views/Home.vue'),
        meta: {
          requiresAuth: true
        }
      },

      //Admin Edit User Password Reset
      {
        path: '/ResetPassword', name: 'Reset Password', component: () => import('../views/ResetPassword'),
        meta: {
          requiresAuth: true
        }
      },


      //Admin Manage WorkOrders
      {
        path: '/ManageOrders/AdminOrderEdit', name: 'Admin Order Edit', component: () => import('../views/ManageOrders/AdminOrderEdit'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageOrders/EditOrders', name: 'Edit Orders', component: () => import('../views/ManageOrders/EditOrders'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageOrders/CreateWorkOrder', name: 'Create Order', component: () => import('../views/ManageOrders/CreateWorkOrder'),
        meta: {
          requiresAuth: true
        }
      },

      //Admin Manage WorkItems
      {
        path: '/ManageItems/AdminItemEdit', name: 'Admin Item Edit', component: () => import('../views/ManageItems/AdminItemEdit'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageItems/EditItems', name: 'Edit Items', component: () => import('../views/ManageItems/EditItems'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageItems/CreateWorkItem', name: 'Create Item', component: () => import('../views/ManageItems/CreateWorkItem'),
        meta: {
          requiresAuth: true
        }
      },

      //Admin Manage Users 

      {
        path: '/ManageUser/ViewUser', name: 'View Users', component: () => import('../views/ManageUser/ViewUser'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageUser/EditUser', name: 'Edit User', component: () => import('../views/ManageUser/AdminEdit'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageUser/CreateUser', name: 'User', component: () => import('../views/ManageUser/CreateUser'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageUser/AdminEdit/:userId', name: 'Admin Edit', component: () => import('../views/ManageUser/AdminEdit'),
        meta: {
          requiresAuth: true
        }
      },

      //Admin Manage Organisation
      {
        path: '/ManageOrganisation/ViewOrganisation', name: 'View Organisations', component: () => import('../views/ManageOrganisation/ViewOrganisation'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageOrganisation/CreateOrganisation', name: 'Create Organisation', component: () => import('../views/ManageOrganisation/CreateOrganisation'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageOrganisation/AdminOrgEdit/:id', name: 'Admin Org Edit', component: () => import('../views/ManageOrganisation/AdminOrgEdit'),
        meta: {
          requiresAuth: true
        }
      },

      //Manage Space

      {
        path: '/ManageSpace/ViewSpace', name: 'View Space', component: () => import('../views/ManageSpace/ViewSpace'),
        meta: {
          requiresAuth: true,
          roles: [
            Roles.Administrator,
            Roles.OrganisationUser,
          ],
        }
      },

      {
        path: '/ManageSpace/EditSpace', name: 'Edit Space', component: () => import('../views/ManageSpace/EditSpace'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageSpace/AdminSpaceEdit/:id', name: 'Admin Space Edit', component: () => import('../views/ManageSpace/AdminSpaceEdit'),
        meta: {
          requiresAuth: true,
          roles: [
            Roles.Administrator,
          ],
        }
      },
      //Manage Space Types
      {
        path: '/ManageSpaceTypes/ViewSpaceTypes', name: 'View Space Types', component: () => import('../views/ManageSpaceTypes/ViewSpaceTypes'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageSpaceTypes/EditSpaceTypes/:id', name: 'Edit Space Types', component: () => import('../views/ManageSpaceTypes/EditSpaceTypes'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageSpaceTypes/CreateSpaceTypes', name: 'Create Space Types', component: () => import('../views/ManageSpaceTypes/CreateSpaceTypes'),
        meta: {
          requiresAuth: true
        }
      },
      //Manage Asset Types

      {
        path: '/ManageAssetTypes/ViewAssetTypes', name: 'View Asset Types', component: () => import('../views/ManageAssetTypes/ViewAssetTypes'),
        meta: {
          requiresAuth: true
        }
      },

      { 
        // TODO: Edit Asset Types
        path: '/ManageAssetTypes/EditAssetTypes/:id', name: 'Edit Asset Types', component: () => import('../views/ManageAssetTypes/EditAssetTypes'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ManageAssetTypes/CreateAssetTypes', name: 'Create Asset Types', component: () => import('../views/ManageAssetTypes/CreateAssetTypes'),
        meta: {
          requiresAuth: true
        }
      },
      //Manage service
      {
        path: '/ManageSpace/AdminSpaceEdit', name: 'Admin Space Create', component: () => import('../views/ManageSpace/AdminSpaceEdit'),
        meta: {
          requiresAuth: true,
          roles: [
            Roles.Administrator,
          ],
        }
      },
      {
        path: '/ManageService/AdminServiceEdit/:id', name: 'Admin Service Edit', component: () => import('../views/ManageService/AdminServiceEdit'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageService/CreateServiceRequest', name: 'Create Service Requests', component: () => import('../views/ManageService/CreateServiceRequest'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/ManageService/EditService', name: 'Edit Service', component: () => import('../views/ManageService/EditService'),
        meta: {
          requiresAuth: true
        }
      },
      //Manage files/ layers
      {
        path: 'ManageLayer/ViewAsset', name: 'View Asset', component: () => import('../views/ManageLayer/EditAsset'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'ManageLayer/EditAsset', name: 'Edit Asset', component: () => import('../views/ManageLayer/EditAsset'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: 'ManageLayer/CreateAsset', name: 'Create Asset', component: () => import('../views/ManageLayer/CreateAsset'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: 'ManageLayer/AdminLayerEdit', name: 'Admin Layer Edit', component: () => import('../views/ManageLayer/AdminLayerEdit'),
        meta: {
          requiresAuth: true
        }
      },

      //views
      {
        path: '/ServiceRequest', name: 'Service Request', component: () => import('../views/ServiceRequest'),
        meta: {
          requiresAuth: true
        }
      },


      {
        path: '/Schedule', name: 'Schedules', component: () => import('../views/Schedule'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Job', name: 'Jobs', component: () => import('../views/Job'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/Report', name: 'Reports', component: () => import('../views/Report'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Assets', name: 'Assets', component: () => import('../views/Assets'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/View', name: 'View', component: () => import('../views/View'),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: '/Space', name: 'Spaces', component: () => import('../views/Space'),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: '/WorkOrder', name: 'Work Orders', component: () => import('../views/WorkOrder'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/WorkItems', name: 'Work Order Items', component: () => import('../views/WorkItems'),
        meta: {
          requiresAuth: true
        }
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    // Check if the user is authenticated (You can implement your own authentication check here)
    if (localStorage.getItem('api_Key') ) {
      const user = JSON.parse(localStorage.getItem('user_data'));
      if (!to.meta.roles || to.meta.roles.includes(user.role)) {
        next();
      } else {
        next('/home');
      }
    } else {
      // User is not authenticated, redirect to login page
      next('/');
    }
  } else {
    // Route does not require authentication, allow navigation
    next();
  }
});

export default router
