<template>
    <div>
        <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
            <b-col cols="3">
                <b-card class="mb-2" style="background-color: #F7F7F7;">
                    <b-card-header style="background-color: #000000;" class="skewed2">
                        <p style="text-align: center; color: #ffff; font-size: 35px;">Having Trouble <br />Logging In?</p>
                    </b-card-header>
                    <img src="@/assets/lock.png" alt="" class="img-to-fit4" />
                    <p style="text-align: center; color: #F3660F; padding-top: 5%!important;">Please enter your email and we'll send you a <br />link to reset your password.</p>
                    <b-card-text>
                        <b-row align-h="center">
                            <b-col cols="12" v-for="notification in notifications" :key="notification.id">
                                <b-alert show :variant="notification.type" dismissible @dismissed="removeNotification(notification)">
                                    {{ notification.message }}
                                </b-alert>
                            </b-col>
                            <b-col cols="10" class="px-0" style="padding-top: 5%!important;">
                                <input type="text" id="email" v-model="email" placeholder="Email" @blur="validateEmail">
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0" style="padding-top: 3%!important;">
                            <b-button class="mt-3 btnprimary2" @click="reset()" size="sm" variant="outline-primary"
                                block squared style="border-radius: 7px!important;">
                                <b-spinner small v-if="isLoading"></b-spinner>
                                Reset Password
                            </b-button>
                        </b-row>
                        <b-row class="mx-0">
                            <b-button class="mt-3 primary-no-border" @click="loginUser()" :disabled="isLoading" size="sm" variant="outline-primary" block squared>
                                Cancel
                            </b-button>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>

</template>
<script>
    import api from '@/api';
    import { mapGetters, mapActions } from 'vuex';
    import emailValidator from '@/validators/emailValidator';

    export default {
        components: {},
        data() {
            return {
            email: null,
            isLoading: false,
            };
        },
        computed: {
            ...mapGetters(['notifications'])
        },
        methods: {     
            ...mapActions(['removeNotification']),   
            validateEmail() {
                const validator = emailValidator(this.email);
                this.validateField("email", validator);
            },
            loginUser() {
                this.isLoading = false
                this.$router.push({
                    path: '/'
                })
            },
            reset() {
                this.$store.commit('setSelectedUserId', null);

                if (this.email !== null && this.isValid()) {
                    this.isLoading = true

                    let e = () => {
                        this.isLoading = false
                        this.$notification.error("Failed to send password reset link. Please check if the email address is correct.");
                    }

                    let s = () => {
                        this.isLoading = false
                        this.$notification.success("Password Reset Link has been sent. Please check your email inbox.");
                    }

                    api.sendRequestToResetPassword(this.email, s, e)
                } else {
                    this.$notification.error("Please complete the form.", 3000);
                }
          }
        },       
    }
</script>
<style>

    .img-to-fit4 {
        width: 20%;
        /* margin-left: 15%; */
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35%;
    }

    .skewed2 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background: #000000;
        z-index: 0;
    }

    .btnprimary2 {
        background-color: #000000 !important;
        color: #fff !important;
        width: 80% !important;
        font-weight: bold;
        border-radius: 10px !important;
    }

    .btnprimary2:hover {
        background-color: #000000 !important;
        opacity: 0.9;
    }
</style>