export const OrganisationType = {
    Owner : 0,
    Subsidiary: 1,
};
export const Roles = {
    Administrator : 'Administrator',
    OrganisationUser: 'Organisation User',
    User: 'User',
    Viewer: 'Viewer',
};
export const Shapes = {
    CIRCLE: 'circle',
    MARKER: 'marker',
    POLYGON : 'polygon',
    POLYLINE : 'polyline',
};