import { v4 as uuidv4 } from 'uuid';

class NotificationService {
    constructor() {
        this.store = null;
    }

    setStore(store) {
        this.store = store;
        return this;
    }

    success(message, duration = 15000) {
        const notification = { id: uuidv4(), type: "success", message };
        this.store.commit("addNotification", notification);
        this.setTimer(notification, duration);
    }

    error(message, duration = 15000) {
        const notification = { id: uuidv4(), type: "error", message };
        this.store.commit("addNotification", notification);
        this.setTimer(notification, duration);
    }

    remove(notification) {
        this.store.commit("removeNotification", notification);
    }

    setTimer(notification, duration) {
        setTimeout(() => {
            this.remove(notification);
        }, duration);
    }
}

export default new NotificationService();