import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./api";
import layerservice from "./services/layerservice";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Multiselect from "vue-multiselect";
import "./style.scss";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import store from "@/store";

import * as VueGoogleMaps from "vue2-google-maps";

import NotificationService from "./services/notificationService";
import { formValidatorService } from '@/services/formValidatorService';

Vue.mixin(formValidatorService);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("multiselect", Multiselect);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBy-iiJJbECRfeieSy0gbmB4Gwl4YX-4tI",
    },
});

NotificationService.setStore(store);

Vue.prototype.$notification = NotificationService;

let api_url =
    process.env.NODE_ENV === "development" ? "api_url_dev" : "api_url";
fetch("/config.json")
    .then((res) => res.json())
    .then((config) => {
        api.Url = config[api_url];
        layerservice.baseURL = config[api_url];

        new Vue({
            router,
            store,
            render: (h) => h(App),
        }).$mount("#app");
    });
